/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ContactSelectTable.vue?vue&type=template&id=0abbd53c&scoped=true"
import script from "./ContactSelectTable.vue?vue&type=script&lang=ts&setup=true"
export * from "./ContactSelectTable.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ContactSelectTable.vue?vue&type=style&index=0&id=0abbd53c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abbd53c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextButton: require('/code/src/components/button/TextButton.vue').default,AlertArea: require('/code/src/components/AlertArea.vue').default,IconButton: require('/code/src/components/button/IconButton.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,Input: require('/code/src/components/input/Input.vue').default,OutlinedTable: require('/code/src/components/table/OutlinedTable.vue').default})
