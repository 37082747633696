import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('CustomDialog', {
    attrs: {
      "title": _setup.$t('extension.syncErrorDetail.title')
    },
    on: {
      "open": _setup.onOpen,
      "close": _setup.onClose
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn() {
        return [_c(VSpacer), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-outline-primary mb-4",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _setup._show = false;
            }
          }
        }, [_vm._v("\n      " + _vm._s(_setup.$t('action.back')) + "\n    ")]), _vm._v(" "), _c(VSpacer)];
      },
      proxy: true
    }]),
    model: {
      value: _setup._show,
      callback: function callback($$v) {
        _setup._show = $$v;
      },
      expression: "_show"
    }
  }, [_c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_setup.errorMessage))]), _vm._v(" "), _setup.crmUrl ? _c('div', {
    staticClass: "text-subtitle-2 mt-6 d-flex flex-wrap align-baseline"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('extension.syncOptionFixData', [_setup.crmName])) + "\n    ")]), _vm._v(" "), _c('LinkText', {
    attrs: {
      "text": _setup.$t('extension.crmObjectDetail', [_setup.crmName, _setup.crmTarget]),
      "to": _setup.crmUrl,
      "external": ""
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-2 mt-6 d-flex flex-wrap align-baseline"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('extension.syncOptionChangeSetting')) + "\n    ")]), _vm._v(" "), _c('LinkText', {
    attrs: {
      "text": _setup.$t('extension.sendwowSetting'),
      "to": "/settings/integration/".concat(_setup.crmName.toLowerCase()),
      "external": ""
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };