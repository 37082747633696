import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.ends-with.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c(VFadeTransition, [_setup.notSetSalutationCount ? _c('AlertArea', {
    staticClass: "mb-4",
    attrs: {
      "type": "info",
      "no-margin": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm._v("\n        " + _vm._s(_setup.$t('info.notSetSalutationCampaign', [_setup.notSetSalutationCount])) + "\n      ")];
      },
      proxy: true
    }], null, false, 1693417117)
  }, [_vm._v(" "), _c('TextButton', {
    attrs: {
      "value": _setup.$t('action.doSetAll')
    },
    on: {
      "click": _setup.onClickAddAllSalutations
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c(VChipGroup, {
    attrs: {
      "active-class": "active",
      "column": ""
    },
    model: {
      value: _setup.filter,
      callback: function callback($$v) {
        _setup.filter = $$v;
      },
      expression: "filter"
    }
  }, _vm._l(_setup.filterInfo, function (info) {
    return _c(VChip, {
      key: info.type,
      staticClass: "text-caption filter-tag",
      attrs: {
        "small": "",
        "rounded": "",
        "outlined": "",
        "value": info.type
      }
    }, [_c(VIcon, {
      attrs: {
        "left": "",
        "size": "14",
        "color": info.color
      }
    }, [_vm._v(_vm._s(info.icon))]), _vm._v("\n      " + _vm._s(info.text) + "\n    ")], 1);
  }), 1), _vm._v(" "), _c('OutlinedTable', {
    staticClass: "mt-2",
    attrs: {
      "value": _setup.selectedContacts,
      "headers": [{
        text: '氏名',
        value: 'name',
        ellipsis: false
      }, {
        text: '敬称',
        value: 'title',
        empty: _setup.$t('notSet')
      }, {
        text: 'メールアドレス',
        value: 'email',
        empty: _setup.$t('notSet')
      }, {
        text: '電話番号',
        value: 'tel',
        empty: _setup.$t('notSet')
      }, {
        text: '会社名',
        value: 'account.name',
        multiLine: true,
        empty: _setup.$t('notSet')
      }, {
        text: '部署',
        value: 'department',
        empty: _setup.$t('notSet')
      }, {
        text: '役職',
        value: 'job_title',
        empty: _setup.$t('notSet')
      },
      // TODO APIの対応が必要 { text: '最終送付日', value: 'last_sent', transform: $date, empty: $t('none') },
      {
        text: '同期状況',
        value: 'syncStatus'
      }],
      "items": _setup.filteredContacts,
      "show-select": "",
      "item-key": "crm_id",
      "loading": _vm.loading,
      "disable-sort": "",
      "select-disable-key": _setup.isUndeliverable,
      "select-disable-message": _setup.getDeliverableMessage,
      "fixed-last-column": ""
    },
    on: {
      "input": _setup.onChangeSelectedContact
    },
    scopedSlots: _vm._u([{
      key: "header.syncStatus",
      fn: function fn(_ref) {
        var header = _ref.header;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_vm._v("\n        " + _vm._s(header.text) + "\n        "), _c('IconButton', {
          staticClass: "ml-0.5",
          attrs: {
            "tooltip": _setup.$t('extension.aboutSyncStatus'),
            "icon": "mdi-help-circle"
          },
          on: {
            "click": _vm.$openAboutSyncStatus
          }
        })], 1)];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [item.delivery_block ? _c('FloatingTooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_c(VIcon, _vm._g({
                staticClass: "mr-1",
                style: {
                  opacity: _setup.isUndeliverable(item) ? 0.4 : 1
                },
                attrs: {
                  "color": "error",
                  "small": ""
                }
              }, on), [_vm._v("\n              mdi-minus-circle\n            ")])];
            }
          }], null, true)
        }, [_vm._v(" "), _c('pre', {
          staticClass: "text-body-2"
        }, [_vm._v(_vm._s(_setup.$t('warning.blockDelivery')))])]) : _vm._e(), _vm._v(" "), _c('LinkText', {
          style: {
            opacity: _setup.isUndeliverable(item) ? 0.4 : 1
          },
          attrs: {
            "to": item.id ? "/contact/".concat(item.id) : null,
            "text": _vm.$fullName(item),
            "external": ""
          }
        })], 1)];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.title ? _c('div', [_vm._v(_vm._s(item.title))]) : item.delivery_block || item.instance_status.endsWith('error') ? _c('div', {
          staticClass: "text-caption disabled-text--text"
        }, [_vm._v("\n        " + _vm._s(_setup.$t('notSet')) + "\n      ")]) : _c('div', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('Input', {
          staticStyle: {
            "width": "65px"
          },
          attrs: {
            "value": _setup.salutations[item.crm_id],
            "placeholder": _setup.$t('notSet'),
            "dense": ""
          },
          on: {
            "input": function input($event) {
              return _setup.onChangeSalutation(item, $event);
            }
          }
        })], 1)];
      }
    }, {
      key: "item.account.name",
      fn: function fn(_ref5) {
        var _item$account, _item$account2, _item$account3, _item$account4, _item$account$name, _item$account5;
        var item = _ref5.item;
        return [(_item$account = item.account) !== null && _item$account !== void 0 && _item$account.id ? _c('LinkText', {
          attrs: {
            "to": "/accounts/".concat((_item$account2 = item.account) === null || _item$account2 === void 0 ? void 0 : _item$account2.id),
            "text": (_item$account3 = item.account) === null || _item$account3 === void 0 ? void 0 : _item$account3.name,
            "external": ""
          }
        }) : (_item$account4 = item.account) !== null && _item$account4 !== void 0 && _item$account4.name || item.crm_company_name ? _c('span', [_vm._v("\n        " + _vm._s((_item$account$name = (_item$account5 = item.account) === null || _item$account5 === void 0 ? void 0 : _item$account5.name) !== null && _item$account$name !== void 0 ? _item$account$name : item.crm_company_name) + "\n      ")]) : _vm._e()];
      }
    }, {
      key: "item.syncStatus",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex align-center primary-text--text"
        }, [(_vm.info = _setup.getSyncStatusInfo(item)) ? void 0 : _vm._e(), _vm._v(" "), _vm.info.icon ? _c(VIcon, {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": _vm.info.iconColor
          }
        }, [_vm._v("\n          " + _vm._s(_vm.info.icon) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "d-flex align-baseline flex-wrap"
        }, [(_vm.hasError = item.instance_status.endsWith('error')) ? void 0 : _vm._e(), _vm._v(" "), _vm.hasError ? _c('TextButton', {
          staticClass: "font-weight-regular mr-2",
          attrs: {
            "text": "",
            "value": _vm.info.text
          },
          on: {
            "click": function click($event) {
              return _setup.emits('click:syncError', item);
            }
          }
        }) : _c('div', {
          staticClass: "text-body-2 mr-2",
          domProps: {
            "textContent": _vm._s(_vm.info.text)
          }
        }), _vm._v(" "), (_vm.syncStatusDetail = _setup.getSyncStatusDetail(item)) ? _c('TextButton', {
          attrs: {
            "value": _vm.syncStatusDetail.text,
            "x-small": ""
          },
          on: {
            "click": _vm.syncStatusDetail.callback
          }
        }) : _vm._e()], 2)], 2)];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };