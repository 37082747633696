import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.ends-with.js";
var render = function render() {
  var _vm$productCustoms$te, _vm$productCustoms$te2, _vm$data$touch, _vm$data$touch2, _vm$data$touch3, _vm$data$touch$shopif, _vm$data$touch$shopif2, _vm$data$touch$shopif3;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _vm.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-primary tw-mr-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  })], 1), _vm._v(" "), _c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('action.creationCampaign')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('FloatingTooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('button', _vm._g({
          staticClass: "tw-btn tw-btn-icon-primary",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchCampaign();
            }
          }
        }, on), [_c('mdi-reload', {
          staticClass: "tw-size-6"
        })], 1)];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t('action.reload')))])]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-outline-primary ml-2",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('logout'))
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/logout'
        });
      }
    }
  })], 1), _vm._v(" "), _c('ContentArea', {
    attrs: {
      "set": _vm.labelNumber = 1
    }
  }, [_c('SectionSubTitle', {
    ref: "destination",
    staticClass: "mb-4"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('destination')) + "\n    ")]), _vm._v(" "), _c('ContactSelectTable', {
    attrs: {
      "loading": _vm.loading.campaign,
      "selected-contacts": _vm.data.contacts,
      "contacts": _vm.selectableContacts,
      "salutations": _vm.data.salutations
    },
    on: {
      "update:selectedContacts": function updateSelectedContacts($event) {
        return _vm.$set(_vm.data, "contacts", $event);
      },
      "update:selected-contacts": function updateSelectedContacts($event) {
        return _vm.$set(_vm.data, "contacts", $event);
      },
      "update:salutations": function updateSalutations($event) {
        return _vm.$set(_vm.data, "salutations", $event);
      },
      "click:similar": function clickSimilar($event) {
        return _vm.$refs.similarDetailDialog.show($event);
      },
      "click:overwrite": function clickOverwrite($event) {
        return _vm.$refs.overwriteDetailDialog.show($event);
      },
      "click:syncError": function clickSyncError($event) {
        return _vm.$refs.syncErrorDetailDialog.show($event);
      }
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step1 ? 'primary' : null
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('campaignName')) + "\n    ")]), _vm._v(" "), _c('Input', {
    attrs: {
      "placeholder": _vm.$t('campaignName'),
      "maxlength": "255"
    },
    model: {
      value: _vm.data.campaignName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "campaignName", $$v);
      },
      expression: "data.campaignName"
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step2 ? 'primary' : null
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.selectionTouch')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "p-relative"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.product
    }
  }), _vm._v(" "), _c('TouchItems', {
    attrs: {
      "selectable": "",
      "value": _vm.data.touch,
      "filters": _vm.filterOptions,
      "options": _vm.tableOptions,
      "type": _vm.type
    },
    on: {
      "update:filters": function updateFilters($event) {
        _vm.filterOptions = $event;
      },
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "update:type": function updateType($event) {
        _vm.type = $event;
      },
      "update:value": _vm.onClickItem
    }
  })], 1), _vm._v(" "), !_vm.isDigitalGift ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step3 ? 'primary' : null
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('action.selectionDestinationAddress')) + "\n        "), _c('IconButton', {
    attrs: {
      "tooltip": _vm.$t('aboutDestinationAddress'),
      "icon": "mdi-help-circle"
    },
    on: {
      "click": _vm.$openAboutDestinationAddress
    }
  })], 1), _vm._v(" "), _c(VFadeTransition, [!_vm.isDigitalGift && _vm.data.contacts.some(function (contact) {
    return !_vm.$isFillAddress(_vm.data.selectedAddresses[contact.crm_id]);
  }) ? _c('div', {
    staticClass: "mb-4"
  }, [_c('AlertArea', {
    attrs: {
      "type": "warning",
      "no-margin": "",
      "message": _vm.$t('warning.noSelectableAddressesContact')
    }
  }), _vm._v(" "), _c('AddressAlert', {
    staticClass: "mt-2",
    attrs: {
      "address": null,
      "at-campaign": ""
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('OutlinedTable', {
    staticClass: "disable-hover",
    attrs: {
      "headers": [{
        text: '氏名',
        value: 'name',
        getter: _vm.$fullName
      }, {
        text: '配送先住所',
        value: 'address'
      }],
      "items": _vm.data.contacts,
      "item-key": "crm_id",
      "disable-sort": "",
      "fixed-last-column": ""
    },
    scopedSlots: _vm._u([{
      key: "item.address",
      fn: function fn(_ref2) {
        var _item$account$shippin, _item$account, _vm$selectableAddress;
        var item = _ref2.item;
        return [(_vm.selectableAddresses = [].concat(_toConsumableArray((_item$account$shippin = (_item$account = item.account) === null || _item$account === void 0 ? void 0 : _item$account.shipping_addresses) !== null && _item$account$shippin !== void 0 ? _item$account$shippin : []), _toConsumableArray(item.shipping_addresses))) ? void 0 : _vm._e(), _vm._v(" "), (_vm$selectableAddress = _vm.selectableAddresses) !== null && _vm$selectableAddress !== void 0 && _vm$selectableAddress.length ? _c('Input', {
          attrs: {
            "value": _vm.data.selectedAddresses[item.crm_id],
            "required": "",
            "type": "select",
            "items": _vm.selectableAddresses,
            "item-text": "label",
            "item-value": "id",
            "return-object": "",
            "placeholder": _vm.$t('placeholder.shippingAddress'),
            "dense": ""
          },
          on: {
            "input": function input($event) {
              _vm.data.selectedAddresses[item.crm_id] = $event;
            },
            "click": function click($event) {
              $event.stopPropagation();
            }
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref3) {
              var address = _ref3.item;
              return [_c('AddressLabel', {
                attrs: {
                  "small": "",
                  "detail": "",
                  "alert-color": "warning",
                  "address": address
                }
              })];
            }
          }, {
            key: "item",
            fn: function fn(_ref4) {
              var _vm$data$selectedAddr;
              var address = _ref4.item,
                attrs = _ref4.attrs;
              return [_c('AddressLabel', {
                attrs: {
                  "detail": "",
                  "address": address,
                  "active-class": attrs.activeClass,
                  "selected": ((_vm$data$selectedAddr = _vm.data.selectedAddresses[address.id]) === null || _vm$data$selectedAddr === void 0 ? void 0 : _vm$data$selectedAddr.id) === address.id
                }
              })];
            }
          }], null, true)
        }) : _c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VIcon, {
          staticClass: "mr-2",
          attrs: {
            "size": "20",
            "color": "warning"
          }
        }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
          staticClass: "text-body-2 warning--text"
        }, [_vm._v(_vm._s(_vm.$t('notSet')))])], 1)];
      }
    }], null, false, 1964536037)
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step4 ? 'primary' : null
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('shipping.selectDesiredArrivalDate')) + "\n        "), _c('IconButton', {
    attrs: {
      "tooltip": _vm.$t('shipping.desiredArrivalDatePrecautions'),
      "icon": "mdi-alert"
    },
    on: {
      "click": _vm.$openDesiredArrivalDatePrecautions
    }
  })], 1), _vm._v(" "), _c('ShippingDateSelect', {
    attrs: {
      "shipping-date": _vm.data.inhouseDate,
      "shipping-method": _vm.data.shippingMethod
    },
    on: {
      "update:shippingDate": function updateShippingDate($event) {
        return _vm.$set(_vm.data, "inhouseDate", $event);
      },
      "update:shipping-date": function updateShippingDate($event) {
        return _vm.$set(_vm.data, "inhouseDate", $event);
      },
      "update:shippingMethod": function updateShippingMethod($event) {
        return _vm.$set(_vm.data, "shippingMethod", $event);
      },
      "update:shipping-method": function updateShippingMethod($event) {
        return _vm.$set(_vm.data, "shippingMethod", $event);
      }
    }
  })] : _vm._e(), _vm._v(" "), ((_vm$productCustoms$te = _vm.productCustoms.texts) === null || _vm$productCustoms$te === void 0 ? void 0 : _vm$productCustoms$te.length) > 0 || _vm.productCustoms.url ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('div', {
    staticClass: "p-relative"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.product
    }
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step5 ? 'primary' : null
    },
    scopedSlots: _vm._u([_vm.complementedContacts.length > 1 && _vm.hasContactPlaceholderText ? {
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "tw-flex tw-items-center tw-gap-2 tw-self-end"
        }, [_c('span', {
          staticClass: "tw-shrink-0 tw-text-subtitle-2"
        }, [_vm._v("\n                " + _vm._s(_vm.$t('usePreviewDestination')) + " :\n              ")]), _vm._v(" "), _c('Input', {
          staticClass: "tw-flex-grow-0 tw-w-48 [&_fieldset]:tw-rounded-none [&_fieldset]:tw-border-0 [&_fieldset]:tw-border-b",
          attrs: {
            "type": "select",
            "items": _vm.complementedContacts,
            "item-text": function itemText(item) {
              return _vm.$fullName(item);
            },
            "item-value": function itemValue(item) {
              return _vm.complementedContacts.indexOf(item);
            },
            "dense": "",
            "clearable": false
          },
          model: {
            value: _vm.textPreviewTargetIndex,
            callback: function callback($$v) {
              _vm.textPreviewTargetIndex = $$v;
            },
            expression: "textPreviewTargetIndex"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._v("\n          " + _vm._s(_vm.$t('shipping.customizePrintedText')) + "\n          ")]), _vm._v(" "), ((_vm$productCustoms$te2 = _vm.productCustoms.texts) === null || _vm$productCustoms$te2 === void 0 ? void 0 : _vm$productCustoms$te2.length) > 0 ? _c('TouchTextViewer', {
    key: "svg-".concat((_vm$data$touch = _vm.data.touch) === null || _vm$data$touch === void 0 ? void 0 : _vm$data$touch.id),
    attrs: {
      "svg": _vm.data.svg,
      "shipping-targets": _vm.complementedContacts.map(function (contact) {
        return {
          contact: contact,
          shippingAddress: _vm.data.selectedAddresses[contact.crm_id]
        };
      }),
      "previewTargetIndex": _vm.textPreviewTargetIndex,
      "qr-url": _vm.data.url,
      "logo": _vm.company.image,
      "customs-texts": _vm.productCustoms.texts,
      "edit": "",
      "required": "",
      "check-placeholder": ""
    },
    on: {
      "update:error": function updateError($event) {
        _vm.hasTextError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _vm.data.customTexts,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "customTexts", $$v);
      },
      expression: "data.customTexts"
    }
  }) : _vm._e(), _vm._v(" "), _vm.productCustoms.url ? _c('Input', {
    key: "url-".concat((_vm$data$touch2 = _vm.data.touch) === null || _vm$data$touch2 === void 0 ? void 0 : _vm$data$touch2.id),
    staticClass: "mt-6",
    attrs: {
      "label": _vm.$t('qrUrl'),
      "required": "",
      "rules": [function (v) {
        return _vm.$isValidUrl(v) || _vm.$t('error.url');
      }]
    },
    model: {
      value: _vm.data.url,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "url", $$v);
      },
      expression: "data.url"
    }
  }) : _vm._e()], 1)] : _vm._e(), _vm._v(" "), _vm.hasGiftProduct ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step6 ? 'primary' : null
    },
    scopedSlots: _vm._u([_vm.complementedContacts.length > 1 && _vm.hasContactPlaceholderGift ? {
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "tw-flex tw-items-center tw-gap-2 tw-self-end"
        }, [_c('span', {
          staticClass: "tw-shrink-0 tw-text-subtitle-2"
        }, [_vm._v("\n              " + _vm._s(_vm.$t('usePreviewDestination')) + " :\n            ")]), _vm._v(" "), _c('Input', {
          staticClass: "tw-flex-grow-0 tw-w-48 [&_fieldset]:tw-rounded-none [&_fieldset]:tw-border-0 [&_fieldset]:tw-border-b",
          attrs: {
            "type": "select",
            "items": _vm.complementedContacts,
            "item-text": function itemText(item) {
              return _vm.$fullName(item);
            },
            "item-value": function itemValue(item) {
              return _vm.complementedContacts.indexOf(item);
            },
            "dense": "",
            "clearable": false
          },
          model: {
            value: _vm.giftPreviewTargetIndex,
            callback: function callback($$v) {
              _vm.giftPreviewTargetIndex = $$v;
            },
            expression: "giftPreviewTargetIndex"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._v("\n        " + _vm._s(_vm.$t('shipping.customizeGiftPage')) + "\n        ")]), _vm._v(" "), _c('GiftPageEditor', {
    key: "gift-".concat((_vm$data$touch3 = _vm.data.touch) === null || _vm$data$touch3 === void 0 ? void 0 : _vm$data$touch3.id),
    attrs: {
      "edit": "",
      "required": "",
      "check-placeholder": "",
      "logo": _vm.company.image,
      "shipping-targets": _vm.complementedContacts.map(function (contact) {
        return {
          contact: contact,
          shippingAddress: _vm.data.selectedAddresses[contact.crm_id]
        };
      }),
      "previewTargetIndex": _vm.giftPreviewTargetIndex,
      "product-title": (_vm$data$touch$shopif = _vm.data.touch.shopify_product.display_gift_name) !== null && _vm$data$touch$shopif !== void 0 ? _vm$data$touch$shopif : _vm.data.touch.shopify_product.product_title,
      "product-image": (_vm$data$touch$shopif2 = _vm.data.touch.shopify_product.display_gift_image) !== null && _vm$data$touch$shopif2 !== void 0 ? _vm$data$touch$shopif2 : (_vm$data$touch$shopif3 = _vm.data.touch.shopify_product.images) === null || _vm$data$touch$shopif3 === void 0 ? void 0 : _vm$data$touch$shopif3[0]
    },
    on: {
      "update:error": function updateError($event) {
        _vm.hasGiftError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _vm.data.lp,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "lp", $$v);
      },
      expression: "data.lp"
    }
  })] : _vm._e()], 2), _vm._v(" "), _vm.isDigitalGift && _vm.crmName === 'Salesforce' && _vm.data.contacts.length ? _c('ContentArea', [_c('SectionSubTitle', {
    staticClass: "mb-4"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('shipping.selectWriteWowlink')) + "\n      "), _c('IconButton', {
    attrs: {
      "tooltip": _vm.$t('settings.integration.aboutSetting'),
      "icon": "mdi-help-circle"
    },
    on: {
      "click": _vm.$openAboutSalesforceWriteWowlink
    }
  })], 1), _vm._v(" "), _c('WriteWowlinkSetting', {
    attrs: {
      "value": _vm.data.writeWowlink,
      "crm-types": _vm.data.contacts.map(function (c) {
        return c.crm;
      }),
      "status": _vm.data.writeWowlinkSettingStatus
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "writeWowlink", $event);
      },
      "update:status": function updateStatus($event) {
        return _vm.$set(_vm.data, "writeWowlinkSettingStatus", $event);
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSubmitEnable
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.doCheckCampaign'))
    },
    on: {
      "click": _vm.confirmSubmit
    }
  })]), _vm._v(" "), _c('TextEditorContextMenu', {
    ref: "textmenu"
  }), _vm._v(" "), _c('OverwriteDetailDialog', {
    ref: "overwriteDetailDialog",
    attrs: {
      "loading": _vm.loading.campaign
    },
    on: {
      "click:reload": _vm.fetchCampaignFromOverwriteDetail
    }
  }), _vm._v(" "), _c('SimilarDetailDialog', {
    ref: "similarDetailDialog",
    on: {
      "update:contact": _vm.fetchCampaign
    }
  }), _vm._v(" "), _c('SyncErrorDetailDialog', {
    ref: "syncErrorDetailDialog"
  }), _vm._v(" "), _c('SyncStatusConfirmDialog', {
    attrs: {
      "show": _vm.dialog.confirm,
      "crm-name": _vm.crmName,
      "has-sync-error": _vm.selectableContacts.filter(function (contact) {
        return contact.instance_status.endsWith('error');
      }).length,
      "has-overwrited": _vm.data.contacts.filter(function (contact) {
        return contact.sync_status === 'unsynchronized';
      }).length,
      "has-similar": _vm.data.contacts.filter(function (contact) {
        return contact.instance_status === 'has_similar';
      }).length
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "confirm", $event);
      },
      "click:positive": _vm.submit,
      "click:negative": function clickNegative($event) {
        return _vm.$refs.destination.$el.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }), _vm._v(" "), _c('OverwriteWowlinkConfirmDialog', {
    ref: "overwriteWowlinkConfirmDialog"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };